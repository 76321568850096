function FooterInstitucional() {
  const lang = navigator.language.split("-")[0]; // Get the language code (es or pt)

  const templateContent  = {
    pt: {
      home: "Início",
      faq: "Perguntas Frequentes (FAQ)",
      templates: "Modelos",
      email: "E-mail:",
      copyright: " SnapMoments. Todos os direitos reservados.",
      whatsapp: "Entre em contato pelo WhatsApp",
    },
    es: {
      home: "Inicio",
      faq: "Preguntas Frecuentes (FAQ)",
      templates: "Plantillas",
      email: "E-mail:",
      copyright: " SnapMoments. Todos los derechos reservados.",
      whatsapp: "Contáctanos por WhatsApp",
    },
    en: {
      home: "Home",
      faq: "Frequently Asked Questions (FAQ)",
      templates: "Templates",
      email: "E-mail:",
      copyright: " SnapMoments. All rights reserved.",
      whatsapp: "Contact us via WhatsApp",
    }
  };
  
  // const content = lang === "pt" ? templateContent.pt : templateContent.es;
  const content = templateContent[lang] 
  ? templateContent[lang] 
  : templateContent['en']; // Fallback to 'en'

  return (
    <footer className="LandingPage-footer">
      <div className="footer-column">
        <div className="footer-links">
          <a href="/" className="footer-link">{content.home}</a>
          <a href="/faq" className="footer-link">{content.faq}</a>
          <a href="/templates" className="footer-link">{content.templates}</a>
        </div>

        <p>
        {content.title}
          <a href="mailto:contacto@snap-moments.com">
            contact@snap-moments.com
          </a>
          <br />
          &copy; {new Date().getFullYear()}{content.copyright}
        </p>

        <div className="whatsapp-button">
          <a
            target="_blank"
            href="https://wa.me/5493795003578"
            className="whatsapp-link"
            rel="noreferrer"
          >
            <i className="fab fa-whatsapp"></i> 
          </a>
        </div>
      </div>
    </footer>
    )
}

export default FooterInstitucional
