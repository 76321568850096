import Compressor from "compressorjs";
import heic2any from "heic2any";

// export const handleFileChange = async (event, setFile, setIsProcessing) => {
//   // Set loading state to true when file processing starts
//   setIsProcessing(true);

//   const handleFileTranslations = {
//     pt: {
//       maxPhotoCount: "Você só pode enviar até 5 fotos.",
//       invalidFileType: "O arquivo não é uma imagem ou extensão válida.",
//       photoExceedsSize: "A foto excede 5 MB após a compressão."
//     },
//     es: {
//       maxPhotoCount: "Solo podés subir hasta 5 fotos.",
//       invalidFileType: "El archivo no es una imagen o extensión válida.",
//       photoExceedsSize: "La foto supera los 5 MB después de la compresión."
//     },
//     en: {
//       maxPhotoCount: "You can only upload up to 5 photos.",
//       invalidFileType: "The file is not a valid image or extension.",
//       photoExceedsSize: "The photo exceeds 5 MB after compression."
//     },
//   };

//   const lang = navigator.language.split("-")[0];
//   const content = handleFileTranslations[lang] || handleFileTranslations["en"];

//   const selectedFiles = Array.from(event.target.files);
//   console.log("Selected Files:", selectedFiles);

//   if (selectedFiles.length > 5) {
//     alert(content.maxPhotoCount);
//     event.target.value = "";
//     setIsProcessing(false);  // Reset loading state
//     return;
//   }

//   const validFiles = selectedFiles.filter((file) => {
//     const isHeic = /\.(heic|heif)$/i.test(file.name);
//     const isImage = file.type.startsWith("image/");
    
//     console.log(`Checking file: ${file.name}`);
//     console.log(`  Type: ${file.type}`);
//     console.log(`  Size: ${file.size} bytes`);
    
//     if (!isImage && !isHeic) {
//       alert(content.invalidFileType);
//       setIsProcessing(false);  // Reset loading state
//       return false;
//     }
//     return true;
//   });

//   const processedFiles = await Promise.all(
//     validFiles.map(async (file) => {
//       if (/\.(heic|heif)$/i.test(file.name)) {
//         console.log(`🔄 Converting HEIC: ${file.name}`);

//         try {
//           let heicBlob = file;

//           // Fallback: Create a Blob manually if the type is missing
//           if (!file.type || file.type === "") {
//             heicBlob = new Blob([file], { type: "image/heic" });
//             console.log("⚠️ File had no MIME type. Using 'image/heic'.");
//           }

//           const blob = await heic2any({
//             blob: heicBlob,
//             toType: "image/jpeg",
//             quality: 0.8,
//           });

//           if (blob instanceof Blob) {
//             const convertedFile = new File(
//               [blob],
//               file.name.replace(/\.(heic|heif)$/i, ".jpg"),
//               {
//                 type: "image/jpeg",
//                 lastModified: new Date().getTime(),
//               }
//             );

//             console.log(`✅ HEIC converted to JPEG: ${convertedFile.name}`);
//             file = convertedFile; // Overwrite file reference
//           } else {
//             console.error("❌ HEIC conversion failed! Unexpected output from heic2any.");
//             return null;
//           }
//         } catch (error) {
//           console.error("⚠️ HEIC conversion error:", error);
//           return null;
//         }
//       }

//       // Compress the image
//       return new Promise((resolve) => {
//         new Compressor(file, {
//           quality: 0.6,
//           success(result) {
//             console.log(`✅ Compression successful: ${result.size} bytes`);
//             if (result.size > 5 * 1024 * 1024) {
//               alert(content.photoExceedsSize);
//               resolve(null);
//             } else {
//               resolve(result);
//             }
//           },
//           error(err) {
//             console.error("❌ Compression error:", err);
//             resolve(null);
//           },
//         });
//       });
//     })
//   );

//   const finalFiles = processedFiles.filter((file) => file !== null);
//   console.log("Final processed files:", finalFiles);
  
//   // Once processing is complete, update state
//   setFile(finalFiles);
//   setIsProcessing(false);  // Reset loading state
// };
export const handleFileChange = async (event, setFile, setIsProcessing, setIsHeicPresent, setButtonText) => {
  setIsProcessing(true);

  const handleFileTranslations = {
    pt: {
      maxPhotoCount: "Você só pode enviar até 5 fotos.",
      invalidFileType: "O arquivo não é uma imagem ou extensão válida.",
      photoExceedsSize: "A foto excede 5 MB após a compressão."
    },
    es: {
      maxPhotoCount: "Solo podés subir hasta 5 fotos.",
      invalidFileType: "El archivo no es una imagen o extensión válida.",
      photoExceedsSize: "La foto supera los 5 MB después de la compresión."
    },
    en: {
      maxPhotoCount: "You can only upload up to 5 photos.",
      invalidFileType: "The file is not a valid image or extension.",
      photoExceedsSize: "The photo exceeds 5 MB after compression."
    },
  };

  const lang = navigator.language.split("-")[0];
  const content = handleFileTranslations[lang] || handleFileTranslations["en"];

  const selectedFiles = Array.from(event.target.files);

  // Detect HEIC files
  const hasHeicFile = selectedFiles.some((file) => /\.(heic|heif)$/i.test(file.name));
  setIsHeicPresent(hasHeicFile); // Update state if HEIC is present

  if (hasHeicFile) {
    setButtonText("Your HEIC file is being uploaded...");
  } else {
    setButtonText(content.fileUploadButton); // Use default upload button text
  }

  console.log("Selected Files:", selectedFiles);

  if (selectedFiles.length > 5) {
    alert(content.maxPhotoCount);
    event.target.value = "";
    setIsProcessing(false); // Reset processing state
    return;
  }

  const validFiles = selectedFiles.filter((file) => {
    const isHeic = /\.(heic|heif)$/i.test(file.name);
    const isImage = file.type.startsWith("image/");

    if (!isImage && !isHeic) {
      alert(content.invalidFileType);
      setIsProcessing(false); // Reset processing state
      return false;
    }
    return true;
  });

  const processedFiles = await Promise.all(
    validFiles.map(async (file) => {
      if (/\.(heic|heif)$/i.test(file.name)) {
        console.log(`🔄 Converting HEIC: ${file.name}`);

        try {
          let heicBlob = file;
          if (!file.type || file.type === "") {
            heicBlob = new Blob([file], { type: "image/heic" });
          }

          const blob = await heic2any({
            blob: heicBlob,
            toType: "image/jpeg",
            quality: 0.8,
          });

          if (blob instanceof Blob) {
            const convertedFile = new File(
              [blob],
              file.name.replace(/\.(heic|heif)$/i, ".jpg"),
              { type: "image/jpeg", lastModified: new Date().getTime() }
            );

            console.log(`✅ HEIC converted to JPEG: ${convertedFile.name}`);
            file = convertedFile; // Overwrite file reference
          } else {
            console.error("❌ HEIC conversion failed!");
            return null;
          }
        } catch (error) {
          console.error("⚠️ HEIC conversion error:", error);
          return null;
        }
      }

      // Compress the image
      return new Promise((resolve) => {
        new Compressor(file, {
          quality: 0.6,
          success(result) {
            if (result.size > 5 * 1024 * 1024) {
              alert(content.photoExceedsSize);
              resolve(null);
            } else {
              resolve(result);
            }
          },
          error(err) {
            console.error("❌ Compression error:", err);
            resolve(null);
          },
        });
      });
    })
  );

  const finalFiles = processedFiles.filter((file) => file !== null);
  setFile(finalFiles); // Update the state with the processed files
  setIsProcessing(false); // Reset processing state
  setIsHeicPresent(false); // Reset HEIC presence flag after processing
};
